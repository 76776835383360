<template>
  <div class="wrapper">
    <div class="main-container">
      <section class="welcome">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 text-left">
              <p class="_heading-first welcome__heading">{{ $t('home.record_your_calls') }}</p>
              <p class="welcome__paragraph">{{ $t('home.record_and_save_any_calls') }}</p>
              <a
                  href="https://simple-recorder.com/onboarding/"
                  class="btn _download-btn welcome__download-btn"
                  target="_blank"
                  @click="onButtonClick('main')"
              >
                {{ $t('home.try_for_free') }}
              </a>
              <div class="__appstore-rate">
                <img src="@/assets/img/logo-appstore.svg" alt="Appstore">
              </div>
              <div class="__rate-star">
                <div class="stars">
                  <img v-for="(_, n) in 5" :key="n" src="@/assets/img/icons/star.svg" alt="star">
                </div>
                <p class="reviews">1.4k {{ $t('home.reviews') }}</p>
              </div>
            </div>
            <div class="col-lg-6">
              <img class="welcome__banner" src="@/assets/img/welcome-banner.png"
                   srcset="@/assets/img/welcome-banner@2x.png 2x, @/assets/img/welcome-banner@3x.png 3x">
              <img src="@/assets/img/welcome-banner-tablet.png"
                   srcset="@/assets/img/welcome-banner-tablet@2x.png 2x, @/assets/img/welcome-banner-tablet@3x.png 3x"
                   alt="Welcome banner"
                   class="welcome__banner-tablet">
            </div>
          </div>
        </div>
      </section>

      <section class="indicators">
        <div class="container">
          <div class="row">
            <div class="col">
              <p class="_heading-third indicators__heading">{{ $t('home.app_in_numbers') }}</p>
              <p class="indicators__paragraph">
                {{ $t('home.user_friendly_interface') }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-xs-12">
              <div class="indicators__item-wrapper">
                <div class="indicators__item-heading">#1 {{ $t('home.business_app') }}</div>
                <div class="indicators__item-paragraph" v-html="$t('home.across_countries')"/>
              </div>
            </div>

            <div class="col-lg-4 col-xs-12">
              <div class="indicators__item-wrapper">
                <div class="indicators__item-heading">2M {{ $t('home.downloads') }}</div>
                <div class="indicators__item-paragraph" v-html="$t('home.that_ensures_growth')"/>
              </div>
            </div>

            <div class="col-lg-4 col-xs-12">
              <div class="indicators__item-wrapper">
                <div class="indicators__item-heading">8.2M {{ $t('home.calls_recorded') }}</div>
                <div class="indicators__item-paragraph" v-html="$t('home.including_incoming_and_outgoing_calls')"/>
              </div>
            </div>
          </div>

          <div class="row indicators__mt">
            <div class="col-lg-4 offset-lg-2">
              <img src="@/assets/img/indicators-banner.png"
                   srcset="@/assets/img/indicators-banner@2x.png 2x, @/assets/img/indicators-banner@3x.png 3x"
                   alt="Indicators-banner"
                   class="indicators__banner">
            </div>
            <div class="col-lg-3 offset-lg-1 align-self-center">
              <a
                  href="https://simple-recorder.com/onboarding/"
                  class="btn _download-btn indicators__download-btn"
                  target="_blank"
                  @click="onButtonClick('numbers')"
              >
                {{ $t('home.try_for_free') }}
              </a>
            </div>
          </div>
        </div>
      </section>

      <section class="features">
        <div class="container">
          <div class="row">
            <div class="col">
              <p class="features__heading _heading-third">{{ $t('home.core_features') }}</p>
              <p class="features__paragraph features__main-paragraph">
                {{ $t('home.unlimited_calls') }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="features__items-wrapper">
                <div class="features__item">
                  <img src="@/assets/img/features-call.svg" alt="call">
                  <p v-html="$t('home.unlimited_call_recordings')"/>
                </div>

                <div class="features__item">
                  <img src="@/assets/img/features-quality.svg" alt="quality">
                  <p v-html="$t('home.high_quality')"/>
                </div>

                <div class="features__item">
                  <img src="@/assets/img/features-recordings.svg" alt="recordings">
                  <p v-html="$t('home.share_recordings')"/>
                </div>

                <div class="features__item">
                  <img src="@/assets/img/features-lang.svg" alt="language">
                  <p v-html="$t('home.multi_language_app')"/>
                </div>
              </div>
              <div class="features__img-wrapper">
                <img src="@/assets/img/three-iphones.png"
                     srcset="@/assets/img/three-iphones@2x.png 2x, @/assets/img/three-iphones@3x.png 3x"
                     alt="iphones">
              </div>
              <img src="@/assets/img/three-iphones-mobile.png"
                   srcset="@/assets/img/three-iphones-mobile@2x.png 2x, @/assets/img/three-iphones-mobile@3x.png 3x"
                   alt="Three iPhones"
                   class="fetures__iphones-img">
              <img src="@/assets/img/three-iphones-tablet.png"
                   srcset="@/assets/img/three-iphones-tablet@2x.png 2x, @/assets/img/three-iphones-tablet@3x.png 3x"
                   alt="Three iPhones"
                   class="fetures__iphones-img-tablet">
              <a
                  href="https://simple-recorder.com/onboarding/"
                  class="btn _download-btn d-block mx-auto features__download-btn"
                  target="_blank"
                  @click="onButtonClick('features')"
              >
                {{ $t('home.try_for_free') }}
              </a>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p class="features__heading-second _heading-third">{{ $t('home.people_love_app') }}</p>
              <p class="features__paragraph">{{ $t('home.become_a_member') }}</p>
              <div class="features__appstore-rec">
                <img src="@/assets/img/logo.svg" alt="Rec-logo">
                <div class="features__appstore-rate-wrapper">
                  <div class="__appstore-rate __appstore-rate-tablet">
                    <img src="@/assets/img/logo-appstore.svg" alt="Appstore">
                  </div>
                  <div class="__rate-star __rate-star-tablet">
                    <div class="stars">
                      <img v-for="(_, n) in 5" :key="n" src="@/assets/img/icons/star.svg" alt="star">
                    </div>
                    <p class="reviews">1.4k {{ $t('home.reviews') }}</p>
                  </div>
                </div>
              </div>
              <a
                  href="https://simple-recorder.com/onboarding/"
                  class="btn _download-btn features__second-download-btn"
                  target="_blank"
                  @click="onButtonClick('reviews')"
              >
                {{ $t('home.try_for_free') }}
              </a>
            </div>
          </div>
        </div>
      </section>

      <section class="download">
        <div class="container">
          <div class="row">
            <div class="col">
              <p class="_heading-second download__heading">
                {{ $t('home.app_providing_quality') }}
              </p>
              <a
                  href="https://simple-recorder.com/onboarding/"
                  class="btn _download-btn d-block mx-auto"
                  target="_blank"
                  @click="onButtonClick('footer')"
              >
                {{ $t('home.try_for_free') }}
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>

  </div>
</template>

<script>
import config from "@/config";

export default {
  data: () => ({
    appStoreUrl: 'https://icallquiz.onelink.me/WJCU/'
  }),
  beforeMount() {
    this.initClarity(config.clarityId.homeId)
  },
  methods: {
    onButtonClick(button) {
      this.amplitudeEvent(`${button}_download_clicked`);
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  margin-bottom: 75px;
  background-color: #FFF;
}

._heading-first {
  font-weight: 600;
  font-size: 58px;
  line-height: 65px;
}

._heading-second {
  font-weight: 600;
  font-size: 56px;
  line-height: 80px;
  text-transform: capitalize;
}

._heading-third {
  font-weight: 600;
  font-size: 42px;
  line-height: 52px;
}

._download-btn {
  min-width: 270px;
  width: fit-content;
  height: 58px;
  background: #007aff;
  border-radius: 12px;
  color: #ffffff;
  font-weight: bold;
  font-size: 22px;
  line-height: 44px;
}

.welcome {
  padding-top: 63px;
  padding-bottom: 251px;
  position: relative;
  z-index: 1;
}

.welcome__heading {
  margin-top: 159px;
}

.welcome__paragraph {
  margin-top: 24px;
  margin-bottom: 32px;
  width: 90%;
  font-size: 16px;
}

.welcome__download-btn {
  margin-bottom: 40px;
}

.features__second-download-btn {
  display: none;
}

.__appstore-rate {
  margin-bottom: 16px;
  text-align: left;
}

.__appstore-rate::after {
  content: "4.9";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  opacity: 0.8;
  margin-left: 5px;
  vertical-align: middle;
}

.stars {
  display: flex;
}

.stars > img {
  margin-right: 4px;
}

.welcome__banner {
  margin-left: auto;
  margin-right: 10px;
  display: block;
}

.reviews {
  margin-top: 4px;
  text-align: left;
}

.indicators {
  padding-bottom: 40px;
}

.indicators__heading {
  text-align: center;
  color: #1e2232;
  margin-bottom: 24px;
}

.indicators__paragraph {
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 70px;
}

.indicators__item-heading {
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
}

.indicators__item-heading::before {
  content: url('~@/assets/img/icons/red-dot.svg');
  margin-right: 8px;
}

.indicators__item-paragraph {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  margin-top: 12px;
}

.indicators__mt {
  margin-top: 80px;
}

.features {
  padding-top: 40px;
  padding-bottom: 80px;
  background: #f9faff;
}

.features__heading {
  text-align: center;
  margin-bottom: 70px;
}

.features__main-paragraph {
  display: none;
}

.features__items-wrapper {
  display: flex;
  width: 70%;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
}

.features__item > p {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
}

.features__item > img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.features__img-wrapper {
  display: flex;
  width: 70%;
  justify-content: space-between;
  margin: 68px auto 70px;
}

.features__heading-second {
  text-align: center;
  margin-bottom: 24px;
  margin-top: 120px;
}

.features__paragraph {
  text-align: center;
  margin-bottom: 62px;
}

.features__appstore-rec {
  display: flex;
  width: 30%;
  justify-content: space-between;
  margin: auto;

  > img {
    width: 100px;
  }
}

.features__appstore-rate-wrapper {
  padding-top: 17px;
}

.download {
  padding-top: 80px;
  padding-bottom: 120px;
}

.download__heading {
  text-align: center;
  margin-bottom: 60px;
  color: #18191d;
  width: 61%;
  margin-left: auto;
  margin-right: auto;
}

.fetures__iphones-img {
  display: none;
}

.fetures__iphones-img-tablet {
  display: none;
}

.welcome__banner-tablet {
  display: none;
}
</style>

<style lang="scss" scoped>
@media (min-width: 320px) and (max-width: 767px) {

  ._heading-first {
    font-style: normal;
    font-weight: 700;
    font-size: 41px;
    line-height: 49px;
    text-align: center;
    text-transform: capitalize;
  }

  .welcome__heading {
    margin-top: 0;
  }

  .welcome__heading > span {
    text-transform: none;
    font-size: 37px;
    font-weight: 600;
  }

  .welcome {
    padding-top: 16px;
    padding-bottom: 80px;
  }

  .welcome__paragraph {
    width: 80%;
    margin: 16px auto 24px;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
  }

  .welcome__download-btn {
    margin-bottom: 36px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .__appstore-rate {
    margin-bottom: 20px;
    text-align: center;
  }

  .__rate-star {
    text-align: center;
    margin-bottom: 32px;
  }
  .stars {
    justify-content: center;
  }

  .welcome__banner {
    margin-left: auto;
    margin-right: auto;
    display: block;
    max-width: 82%;
  }

  ._heading-third {
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
  }

  .indicators__paragraph {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    margin-bottom: 0px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .indicators__item-wrapper {
    margin-top: 40px;
  }

  .indicators__mt {
    margin-top: 24px;
  }

  .indicators__banner {
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* width: 298.86px; */
    max-width: 82%;
    margin-bottom: 60px;
  }

  .indicators__download-btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .features__second-download-btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .indicators {
    padding-bottom: 80px;
  }

  .features {
    padding-bottom: 28px;
  }

  .features__heading {
    margin-bottom: 24px;
  }

  .features__items-wrapper {
    display: none;
  }

  .features__paragraph {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
  }

  .features__main-paragraph {
    display: block;
    margin-bottom: 36px;
  }

  .features__heading-second {
    margin-top: 32px;
    margin-bottom: 24px;
  }

  .features__download-btn {
    display: none !important;
  }

  .features__appstore-rec {
    display: block;
    width: 100%;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }

  .features__appstore-rec > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .reviews {
    margin-top: 10px;
    text-align: center;
  }

  .download {
    display: none;
  }

  .footer_copy {
    font-size: 12px;
    line-height: 18px;
    width: 100%;
    margin-left: auto;
    margin-right: 10px;
  }

  .footer_paragraph {
    font-size: 12px;
    line-height: 18px;
    margin-top: 16px;
  }

  .footer__logo {
    width: 34px;
    height: 34px;
    margin-left: 10px;
  }

  .footer {
    padding-top: 20px;
    padding-bottom: 24px;
  }

  .footer__policy-terms-wrapper {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
  }

  .features__img-wrapper {
    display: none;
  }

  .fetures__iphones-img {
    max-width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .welcome__banner-tablet {
    display: none;
  }
  .lang-select {
    right: 10%;
  }
}


@media (orientation: landscape) and (max-width: 1024px) {
  .purchase-footer {
    .politics {
      display: flex;
      flex-direction: row;

      li:nth-child(2) {
        padding: 0 10px;
      }
    }

    .info {
      width: 40%;
      text-align: center;
    }
  }

}
</style>

<style lang="scss" scoped>
@media (min-width: 768px) and (max-width: 1024px) {
  .header__logo {
    margin-left: 28px;
  }

  .welcome__heading {
    margin-top: 0px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  .welcome {
    padding-top: 32px;
    padding-bottom: 111px;
  }

  ._heading-first {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    color: #000000;
  }

  .welcome__paragraph {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }

  .welcome__paragraph {
    margin-top: 16px;
  }

  .welcome__download-btn {
    margin-bottom: 24px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .lang-select {
    right: 5%;
  }

  .__appstore-rate {
    margin-bottom: 14px;
    text-align: center;
  }

  .__rate-star {
    text-align: center;
    margin-bottom: 24px;
  }

  .stars {
    justify-content: center;
  }

  .welcome__banner {
    display: none;
  }

  .welcome__banner-tablet {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  ._heading-third {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
  }

  .indicators__heading {
    margin-bottom: 16px;
  }

  .indicators__paragraph {
    margin-bottom: 0px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    opacity: 0.7;
  }

  .indicators__item-heading {
    margin-top: 24px;
  }

  .indicators__mt {
    margin-top: 29px;
  }

  .indicators__banner {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 40px;
  }

  .indicators__download-btn {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .indicators {
    padding-bottom: 80px;
  }

  .features__items-wrapper {
    width: 88%;
  }

  .features__heading {
    margin-bottom: 32px;
  }

  .features__img-wrapper {
    display: none;
  }

  .fetures__iphones-img-tablet {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 49px;
    margin-bottom: 40px;
  }

  .features__heading-second {
    margin-top: 80px;
    margin-bottom: 16px;
  }

  .features__paragraph {
    margin-bottom: 24px;
  }

  .features__appstore-rec {
    width: 47%;
  }

  .__rate-star-tablet {
    text-align: left;
  }

  .__appstore-rate-tablet {
    text-align: left;
  }

  .reviews {
    margin-top: 10px;
  }

  ._heading-second {
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;
    text-align: center;
    text-transform: capitalize;
  }

  .download__heading {
    width: 81%;
  }

  .download {
    padding-bottom: 80px;
  }

  .footer__logo {
    margin-left: 20px;
  }

  .footer_copy {
    margin-right: 20px;
    font-size: 14px;
    line-height: 17px;
  }

  .footer_paragraph {
    margin-top: 12px;
    font-size: 14px;
    line-height: 17px;
  }

  .footer__policy-terms-wrapper {
    font-size: 14px;
    line-height: 17px;
  }
}
</style>